<template>
  <v-app>
    <courses-core-drawer/>

    <courses-core-app-bar />

    <courses-core-view />

  </v-app>
</template>
<script>
export default {
  name: "CoursesIndex",

  components: {
    CoursesCoreDrawer: () => import("@/views/courses/components/core/Drawer"),
    CoursesCoreAppBar: () => import("@/views/courses/components/core/AppBar"),
    CoursesCoreView: () => import("@/views/courses/components/core/View"),
  },

  data: () => ({
    expandOnHover: false,
  }),
};
</script>
<style>
:root {
  --plyr-color-main: #740286;
}
.plyr__control--overlaid {
  background: #740286;
  background: var(
    --plyr-video-control-background-hover,
    var(--plyr-color-main, var(--plyr-color-main, #740286))
  );
}
.plyr--full-ui {
  max-width: 100%;
  height: auto;
}
</style>